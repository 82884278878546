import {getOutlet} from 'reconnect.js';
const LocaleOutlet = getOutlet('locale');

export const UiState = {
  CHOOSE_TIME: 0,
  CHOOSE_ROOM: 1,
  CONFIRM: 2,
};

export function getDayNames() {
  const dic = {
    en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    tw: ['日', '一', '二', '三', '四', '五', '六'],
  };
  return dic[LocaleOutlet.getValue().lang];
}

export function zeroPadding(x) {
  return ('00' + x).slice(-2);
}

export function DT({year, month, date}) {
  return new Date(year, month, date);
}

export function DTS({year, month, date}) {
  return `${year}-${zeroPadding(month + 1)}-${zeroPadding(date)}`;
}

export function getWeekday({year, month, date}) {
  const _date = new Date(year, month, date);
  // month start from 0
  // Sunday = 0, Monday = 1, ... (See below):
  const weekday = _date.getDay();

  const DAY_NAMES = getDayNames();
  return DAY_NAMES[weekday];
}

export function checkEmail(email) {
  const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return emailPattern.test(email);
}

export function checkPhone(phone) {
  const phonePattern = /^09[0-9]{8}$/;
  return phonePattern.test(phone);
}
