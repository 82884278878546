import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as Ant from 'antd';
import Carousel from '../../Components/Carousel';
import FixedRatioImage from '../../Components/FixedRatioImage';
import {mapLineBreak} from '../../Utils/TextUtil';
import * as AppActions from '../../AppActions';

function mapFakeItems(items, index, Comp) {
  if (items.length === index + 1) {
    return [0, 1, 2, 3, 4].map((item) => (
      <Comp style={{height: 0}} key={item} />
    ));
  }

  return null;
}

export default function RoomDetailModal(props) {
  const {visible, setVisible} = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={720}
      visible={visible}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}
      destroyOnClose={true}>
      <ModalContent {...props} />
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const {room, chooseRoom} = props;
  const [dimension] = useOutlet('dimension');
  const [imgIdx, setImgIdx] = React.useState(0);
  const [qty, setQty] = React.useState(1);

  let gallerySize = 450;
  if (dimension.innerWidth) {
    if (dimension.innerWidth < 450 + 30 * 2) {
      gallerySize = dimension.innerWidth - 30 * 2;
    }
  }

  return (
    <Wrapper>
      <Gallery dimension={dimension} size={gallerySize}>
        {room.images && room.images[imgIdx] && (
          <Carousel
            currIdxFromParent={imgIdx}
            width={gallerySize}
            height={gallerySize}
            data={room.images.map((i) => i.expected_url)}
            renderPrev={null}
            renderNext={null}
            renderDots={null}
            renderItem={({item}) => {
              return (
                <FixedRatioImage
                  image={item}
                  width="100%"
                  ratio={1}
                  mode="cover"
                  alt="room"
                  extraCss="border-radius: 15px;"
                />
              );
            }}
            disableSwipe
          />
        )}

        {room.images && (
          <MiniImageList>
            {room.images.map((image, index) => (
              <React.Fragment key={index}>
                <MiniImageItem
                  src={image.expected_url}
                  alt="mini"
                  key={index}
                  selected={index === imgIdx}
                  onClick={() => setImgIdx(index)}
                />
                {mapFakeItems(room.images, index, MiniImageItem)}
              </React.Fragment>
            ))}
          </MiniImageList>
        )}
      </Gallery>

      <h2>{room.name}</h2>
      <div className="price">NT$ {room.current_price}</div>

      <Ant.Tabs className="tabs">
        <Ant.Tabs.TabPane tab="介紹" key="intro">
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: mapLineBreak(room.intro),
            }}
          />
        </Ant.Tabs.TabPane>
        <Ant.Tabs.TabPane tab="規格" key="spec">
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: mapLineBreak(room.spec),
            }}
          />
        </Ant.Tabs.TabPane>
        <Ant.Tabs.TabPane tab="入住須知" key="remark">
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: mapLineBreak(room.remark),
            }}
          />
        </Ant.Tabs.TabPane>
      </Ant.Tabs>

      <Ant.Divider />

      <div className="row" style={{justifyContent: 'flex-end'}}>
        <label style={{marginRight: 6, color: '#888'}}>
          {AppActions.L('quantity')}
        </label>
        <Ant.Select
          defaultValue={1}
          value={qty}
          onChange={(nextQty) => setQty(nextQty)}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].slice(0, room.remaining).map((i) => {
            return (
              <Ant.Select.Option key={i} value={i}>
                {i}
              </Ant.Select.Option>
            );
          })}
        </Ant.Select>
        <div style={{margin: '0 5px', color: '#888'}}>x</div>
        <div className="price" style={{marginRight: 10}}>
          NT$ {room.current_price}
        </div>
        <Ant.Button type="primary" onClick={() => chooseRoom(room, qty)}>
          {AppActions.L('select_this_room')}
        </Ant.Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 40px 20px;
  & .row {
    display: flex;
    align-items: center;
  }

  & .price {
    font-size: 1.4em;
  }
`;

const Gallery = styled.div`
  width: ${(props) => props.size}px;
  margin-bottom: 20px;
  margin: 0 auto;

  & > img:first-child {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    object-fit: contain;
  }
`;

const MiniImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 500px) {
    justify-content: space-around;
  }
`;

const MiniImageItem = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 4px;
  object-fit: contain;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  margin: 10px;
  cursor: pointer;

  :first-child,
  :nth-child(5n + 1) {
    margin-left: 0px;
  }

  :last-child,
  :nth-child(5n) {
    margin-right: 0px;
  }

  :hover,
  :active {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  @media (max-width: 500px) {
    width: 62px;
    height: 62px;
  }

  @media (max-width: 430px) {
    margin: 10px 4px;
    width: 58px;
    height: 58px;
  }

  ${(props) => props.css || ''}
`;
