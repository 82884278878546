import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import * as Ant from 'antd';
import {DTS} from './Util';
import * as AppActions from '../../AppActions';
import {L} from '../../AppActions';

export default function RoomCartConfirm(props) {
  const {
    startDate,
    endDate,
    roomCart,
    byAdmin,
    extraValues,
    setExtraValues,
    price,
    setPrice,
  } = props;

  return (
    <Wrapper>
      <div className="content">
        <h2>{L('overview')}</h2>

        <div className="info-field">
          <label>{L('date')}</label>
          <div>
            {DTS(startDate)} ~ {DTS(endDate)}
          </div>
        </div>

        <div className="info-field">
          <label>{L('expect_arrival_time')}</label>
          <Ant.TimePicker
            format="HH:mm"
            defaultValue={moment(extraValues.arrival_time, 'HH:mm')}
            style={{maxWidth: 120}}
            onChange={(value, dateString) => {
              console.log('dataString', dateString);
              setExtraValues({...extraValues, arrival_time: dateString});
            }}
          />
          <div style={{color: '#bbb'}}>
            下午六點後抵達客人，請改為電話或信箱訂房。
          </div>
        </div>

        <div className="info-field">
          <label>{L('room')}</label>
          <div className="col">
            {(roomCart?.items || []).map((r, idx) => {
              return (
                <div className="row" key={idx}>
                  <div>
                    {r.name} x {r.config.qty}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="info-field">
          <label>{L('adults')}</label>
          <Ant.Input
            type="number"
            value={extraValues.adults}
            style={{maxWidth: 120}}
            min={0}
            onChange={(e) =>
              setExtraValues({...extraValues, adults: e.target.value})
            }
          />
        </div>

        <div className="info-field">
          <label>{L('kids')}</label>
          <Ant.Input
            type="number"
            value={extraValues.kids}
            style={{maxWidth: 120}}
            min={0}
            onChange={(e) =>
              setExtraValues({...extraValues, kids: e.target.value})
            }
          />
        </div>

        <h2 style={{marginTop: 30}}>{AppActions.L('contact_info')}</h2>

        <div className="info-field">
          <label>{L('your_name')}</label>
          <Ant.Input
            value={extraValues.buyer_name}
            style={{maxWidth: 240}}
            min={0}
            onChange={(e) =>
              setExtraValues({...extraValues, buyer_name: e.target.value})
            }
          />
        </div>

        <div className="info-field">
          <label>{L('your_email')}</label>
          <Ant.Input
            value={extraValues.buyer_email}
            style={{maxWidth: 240}}
            min={0}
            onChange={(e) =>
              setExtraValues({...extraValues, buyer_email: e.target.value})
            }
          />
        </div>

        <div className="info-field">
          <label>{L('your_phone')}</label>
          <Ant.Input
            value={extraValues.buyer_phone}
            style={{maxWidth: 240}}
            min={0}
            onChange={(e) =>
              setExtraValues({...extraValues, buyer_phone: e.target.value})
            }
          />
        </div>

        <h2 style={{marginTop: 30}}>{L('other_info')}</h2>

        <div className="info-field">
          <label>{L('order_note')}</label>
          <Ant.Input.TextArea
            value={extraValues.order_note}
            style={{minHeight: 100}}
            placeholder="葷食、蛋奶素、全素、不吃牛肉、三個月內是否有來住過觀霧山莊"
            onChange={(e) =>
              setExtraValues({...extraValues, order_note: e.target.value})
            }
          />
        </div>

        <Ant.Divider />

        {byAdmin ? (
          <div className="info-field" style={{marginBottom: 10}}>
            <label>{L('total')}</label>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{marginRight: 10}}>NT$ </div>
              <Ant.Input
                value={price}
                onChange={(e) => setPrice(parseInt(e.target.value))}
                style={{maxWidth: 240}}
              />
              <div style={{marginLeft: 10}}>
                {L('room_price')} NT$ {roomCart.total}
              </div>
            </div>
          </div>
        ) : (
          <div className="total-price-bar">
            <div>{L('total')}</div>
            <div style={{margin: '0 8px', fontWeight: 'bold', fontSize: 18}}>
              NT$ {roomCart.subtotal}
            </div>
            <div>{L('deposit')}</div>
            <h3>NT$ {roomCart.total}</h3>
          </div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .content {
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;

    & > .info-field {
      & label {
        display: block;
        color: #888;
        margin-top: 8px;
      }
    }

    & .total-price-bar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;

      & > h3 {
        margin-left: 6px;
        font-size: 2rem;
      }
    }
  }
`;
