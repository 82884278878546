import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {UiState, DTS, getWeekday} from './Util';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';

export default function BookingSummary(props) {
  const [roomCart] = useOutlet('roomCart');
  const {
    uiState,
    startDate,
    endDate,
    extraValues,
    clearDate,
    confirmDate,
    confirmRoom,
    removeChosenRoom,
    checkout,
    goBack,
    byAdmin,
    setOpenNoticeDialog,
  } = props;

  return (
    <>
      <Wrapper>
        <div className="content">
          {uiState === UiState.CHOOSE_TIME && (
            <div className="row">
              <Ant.Button
                size="large"
                onClick={() => setOpenNoticeDialog(true)}>
                {AppActions.L('booking_notice')}
              </Ant.Button>
              <div className="summary">
                <div className="field">
                  <label style={{color: '#888'}}>
                    {AppActions.L('checkin_date')}
                  </label>
                  <div>
                    {(startDate && (
                      <div>
                        {DTS(startDate)} {getWeekday(startDate)}
                      </div>
                    )) || <div>---</div>}
                  </div>
                </div>

                <div className="field">
                  <label style={{color: '#888'}}>
                    {AppActions.L('checkout_date')}
                  </label>
                  <div>
                    {(endDate && (
                      <div>
                        {DTS(endDate)} {getWeekday(endDate)}
                      </div>
                    )) || <div>---</div>}
                  </div>
                </div>
              </div>
              {(startDate || endDate) && (
                <Ant.Button
                  size="large"
                  onClick={clearDate}
                  style={{marginRight: 10}}>
                  {AppActions.L('reset')}
                </Ant.Button>
              )}
              <Ant.Button
                type="primary"
                size="large"
                icon={<RightOutlined />}
                disabled={!startDate || !endDate}
                onClick={confirmDate}>
                {AppActions.L('next_step')}
              </Ant.Button>
            </div>
          )}

          {uiState === UiState.CHOOSE_ROOM && (
            <div className="row">
              <Ant.Button
                type="primary"
                size="large"
                onClick={goBack}
                icon={<LeftOutlined />}>
                {AppActions.L('go_back')}
              </Ant.Button>

              <div className="summary">
                <div className="field">
                  <label>{AppActions.L('date')}</label>
                  <div>
                    {DTS(startDate)} {getWeekday(startDate)}
                  </div>
                  <div style={{margin: '0 5px'}}> ~ </div>
                  <div>
                    {DTS(endDate)} {getWeekday(endDate)}
                  </div>
                </div>

                <div className="field">
                  <label>{AppActions.L('room')}</label>
                  <div className="col">
                    {roomCart?.items.length === 0 &&
                      AppActions.L('not_selected_yet')}
                    {(roomCart?.items || []).map((r, idx) => {
                      return (
                        <div
                          className="row"
                          key={idx}
                          style={{marginBottom: 5}}>
                          <div className="room-name">
                            {r.name} x {r.config.qty}
                          </div>
                          <Ant.Button
                            shape="circle"
                            danger
                            size="small"
                            style={{marginLeft: 5}}
                            icon={<CloseOutlined size={14} />}
                            onClick={() => {
                              removeChosenRoom(idx);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <Ant.Button
                type="primary"
                size="large"
                icon={<RightOutlined />}
                disabled={(roomCart?.items || []).length === 0}
                onClick={() => confirmRoom()}>
                {AppActions.L('next_step')}
              </Ant.Button>
            </div>
          )}

          {uiState === UiState.CONFIRM && (
            <div className="row">
              <Ant.Button
                type="primary"
                size="large"
                onClick={goBack}
                icon={<LeftOutlined />}>
                {AppActions.L('go_back')}
              </Ant.Button>

              <div className="summary">
                <div className="field">
                  <label>{AppActions.L('date')}</label>
                  <div>
                    {DTS(startDate)} {getWeekday(startDate)}
                  </div>
                  <div style={{margin: '0 5px'}}> ~ </div>
                  <div>
                    {DTS(endDate)} {getWeekday(endDate)}
                  </div>
                </div>

                <div className="field">
                  <label>{AppActions.L('room')}</label>
                  <div className="col">
                    {roomCart?.items.length === 0 &&
                      AppActions.L('not_selected_yet')}
                    {(roomCart?.items || []).map((r, idx) => {
                      return (
                        <div className="row" key={idx}>
                          <div>
                            {r.name} x {r.config.qty}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="field">
                  <label>{AppActions.L('deposit')}</label>
                  <div>NT$ {roomCart.total}</div>
                  <div style={{marginLeft: 10}}>({AppActions.L('total')}</div>
                  <div>NT$ {roomCart.subtotal})</div>
                </div>
              </div>

              <Ant.Button
                type="primary"
                size="large"
                icon={<RightOutlined />}
                disabled={
                  !extraValues.buyer_name ||
                  !extraValues.buyer_email ||
                  !extraValues.buyer_phone ||
                  (roomCart?.items || []).length === 0
                }
                onClick={() => checkout()}>
                {byAdmin ? '後台確認訂房' : AppActions.L('go_to_checkout')}
              </Ant.Button>
            </div>
          )}
        </div>
      </Wrapper>

      <div style={{height: 150}} />
    </>
  );
}

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px;
  border-top: 5px solid teal;
  background-color: white;

  & > .content {
    max-width: var(--contentMaxWidth);
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;

    & .row {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      height: 100%;
    }

    & .col {
      display: flex;
      flex-direction: column;
    }

    & .field {
      display: flex;
      align-items: center;
      & label {
        width: 120px;
      }
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }

    & .summary {
      flex: 1;
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    & > .content {
      padding: 0;

      & .field {
        & .room-name {
          white-space: nowrap;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      & .summary {
        flex: 1 0 100%;
        order: -1;
        margin-left: 0;
        margin-bottom: 20px;
      }
    }
  }
`;
