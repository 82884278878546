import React, {useState} from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import {UiState, DT, DTS, checkEmail, checkPhone, getDayNames} from './Util';
import DateCell from './DateCell';
import Summary from './Summary';
import RoomList from './RoomList';
import RoomCartConfirm from './RoomCartConfirm';
import * as AppActions from '../../AppActions';
import * as BookingActions from './BookingActions';
import * as AsyncStorage from 'rev.sdk.js/Utils/AsyncStorage';
import constants from '../../constants';
import NoticeModal from './NoticeModal';
import Calendar from 'rev.sdk.js/Components/Calendar';

export default function BookingApp(props) {
  const {
    pageContext: {byAdmin = false},
  } = props;
  const [locale, setLocale] = useOutlet('locale');
  const [user] = useOutlet('user');
  const [roomCart, setRoomCart] = useOutlet('roomCart');
  const [tmpStartDate, setTmpStartDate] = React.useState(null);
  const [tmpEndDate, setTmpEndDate] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [rooms, setRooms] = React.useState([]);
  const [extraValues, setExtraValues] = React.useState({
    adults: 0,
    kids: 0,
    order_note: '',
    buyer_name: '',
    buyer_phone: '',
    buyer_email: '',
    arrival_time: '15:00',
  });
  const [price, setPrice] = React.useState(0);
  const [showRoomCart, setShowRoomCart] = React.useState(false);
  const [openNoticeDialog, setOpenNoticeDialog] = useState(true);
  const minDate = new Date();
  minDate.setDate(minDate.getDate());
  minDate.setHours(23, 59, 59, 999);

  React.useEffect(() => {
    async function fetchRoomCart() {
      AppActions.setLoading(true);
      try {
        await BookingActions.fetchRoomCart();
      } catch (ex) {
        console.warn(ex);
      }
      AppActions.setLoading(false);
    }

    if (user) {
      fetchRoomCart();
    }
  }, [user, setRoomCart]);

  const fetchRooms = React.useCallback(async () => {
    if (startDate && endDate) {
      AppActions.setLoading(true);
      try {
        setRooms(
          await BookingActions.searchRoom({
            from_date: DTS(startDate),
            to_date: DTS(endDate),
          }),
        );
        await BookingActions.clearRoomCart();
      } catch (ex) {
        console.warn(ex);
      }
      AppActions.setLoading(false);
    }
  }, [startDate, endDate]);

  React.useEffect(() => {
    fetchRooms();
  }, [fetchRooms]);

  const uiState =
    !startDate || !endDate
      ? UiState.CHOOSE_TIME
      : !showRoomCart
      ? UiState.CHOOSE_ROOM
      : UiState.CONFIRM;

  React.useEffect(() => {
    setTimeout(() => {
      window.scroll({top: 0, behavior: 'smooth'});
    }, 300);
  }, [uiState]);

  function goBack() {
    if (uiState === UiState.CHOOSE_ROOM) {
      setTmpStartDate(null);
      setTmpEndDate(null);
      setStartDate(null);
      setEndDate(null);
    } else if (uiState === UiState.CONFIRM) {
      setShowRoomCart(false);
    }
  }

  async function chooseRoom(room, qty) {
    AppActions.setLoading(true);
    try {
      await BookingActions.addRoomToCart({
        room,
        qty,
        from_date: DTS(startDate),
        to_date: DTS(endDate),
      });
    } catch (ex) {
      console.warn(ex);
      alert('發生錯誤');
    }
    AppActions.setLoading(false);
  }

  async function removeChosenRoom(idx) {
    AppActions.setLoading(true);
    try {
      await BookingActions.removeRoomFromCart(idx);
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }

  async function checkout() {
    const {
      order_note,
      buyer_name,
      buyer_email,
      buyer_phone,
      arrival_time,
    } = extraValues;
    const adults = parseInt(extraValues.adults);
    const kids = parseInt(extraValues.kids);

    if (isNaN(adults) || isNaN(kids)) {
      alert('人數輸入有誤, 請重新填寫');
      return;
    } else if (adults < 1) {
      alert('人數輸入有誤, 成人人數不可小於1, 請重新填寫!');
      return;
    } else if (!buyer_name || !buyer_email || !buyer_phone) {
      alert('您的大名/email/電話皆為必填欄位');
      return;
    } else if (!checkEmail(buyer_email)) {
      alert('Email格式有誤');
      return;
    } else if (!checkPhone(buyer_phone)) {
      alert('手機格式有誤');
      return;
    }

    try {
      AppActions.setLoading(true);
      if (byAdmin) {
        const adminAccessToken = await AsyncStorage.getItem(
          'admin-access-token',
        );

        if (!adminAccessToken) {
          alert('權限不足');
          return;
        }

        await BookingActions.editRoomCartConfig({
          buyer_name,
          buyer_email,
          buyer_phone,
          arrival_time,
          payment_subtype: 'credit',
          order_note,
        });

        let order = await BookingActions.adminReserveRoom({
          bookingInfo: {
            buyer_name,
            buyer_email,
            buyer_phone,
            adults,
            kids,
            price,
          },
          adminAccessToken,
        });
        alert('預約成功, 即將前往訂單詳細頁');

        order.id = order._id.$oid;
        let tmp_token = user.token;
        AppActions.navigate(
          `/booking/order?order_id=${order.order_id}&tmp_token=${tmp_token}`,
        );
      } else {
        await BookingActions.editRoomCartConfig({
          buyer_name,
          buyer_email,
          buyer_phone,
          arrival_time,
          payment_subtype: 'credit',
          order_note,
        });
        await BookingActions.checkoutBookingRequest({adults, kids});
      }
    } catch (ex) {
      console.warn('RoomCartConfirm.doCheckout ex', ex);

      if (ex?.response?.error === 'room_sold_out') {
        alert('您所選的房間已售完，請重新選擇。');
        setShowRoomCart(false);
        fetchRooms();
      } else {
        alert(`您的資料有誤! 請再次檢查！\n${JSON.stringify(ex)}`);
      }
    } finally {
      AppActions.setLoading(false);
    }
  }

  return (
    <Wrapper>
      <div className="content">
        <div className="block">
          <div style={{padding: 20}}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 20,
              }}>
              <div style={{flex: 1}} />
              <Ant.Alert
                message="下午六點後抵達客人，請改為電話或信箱訂房。"
                showIcon
                style={{padding: '4px 15px', marginRight: 5}}
              />
              <Ant.Select
                value={locale.lang}
                onChange={(nextLang) => setLocale({lang: nextLang})}
                style={{width: 90}}>
                <Ant.Select.Option value="tw">中文</Ant.Select.Option>
                <Ant.Select.Option value="en">English</Ant.Select.Option>
              </Ant.Select>
            </div>
            <div>
              <Ant.Steps current={uiState} responsive={false}>
                <Ant.Steps.Step
                  key={UiState.CHOOSE_TIME}
                  title={AppActions.L('tab_choose_date')}
                />
                <Ant.Steps.Step
                  key={UiState.CHOOSE_ROOM}
                  title={AppActions.L('tab_choose_room')}
                />
                <Ant.Steps.Step
                  key={UiState.CONFIRM}
                  title={AppActions.L('tab_confirm')}
                />
              </Ant.Steps>
            </div>
          </div>

          {uiState === UiState.CHOOSE_TIME && (
            <div className="rev-calendar-container">
              <Calendar
                dayNames={getDayNames()}
                minDate={minDate}
                onSelect={(data) => {
                  console.log('DBG', data);
                  if (!tmpStartDate) {
                    setTmpStartDate({...data});
                  } else if (!endDate) {
                    const startDt = DT(tmpStartDate);
                    const endDt = DT(data);
                    if (startDt.getTime() >= endDt.getTime()) {
                      alert(AppActions.L('invalid_date'));
                      return;
                    }
                    setTmpEndDate({...data});
                  }
                }}
                renderDate={(props) => (
                  <DateCell
                    key={props.date}
                    {...props}
                    startDate={startDate || tmpStartDate}
                    endDate={endDate || tmpEndDate}
                  />
                )}
              />
            </div>
          )}

          {uiState === UiState.CHOOSE_ROOM && (
            <RoomList rooms={rooms} chooseRoom={chooseRoom} />
          )}

          {uiState === UiState.CONFIRM && (
            <RoomCartConfirm
              startDate={startDate || tmpStartDate}
              endDate={endDate || tmpEndDate}
              roomCart={roomCart}
              byAdmin={byAdmin}
              extraValues={extraValues}
              setExtraValues={setExtraValues}
              price={price}
              setPrice={setPrice}
            />
          )}
        </div>

        <Summary
          uiState={uiState}
          startDate={startDate || tmpStartDate}
          endDate={endDate || tmpEndDate}
          extraValues={extraValues}
          clearDate={() => {
            setTmpStartDate(null);
            setTmpEndDate(null);
            setStartDate(null);
            setEndDate(null);
          }}
          confirmDate={() => {
            setStartDate(tmpStartDate);
            setEndDate(tmpEndDate);
          }}
          confirmRoom={async () => {
            AppActions.setLoading(true);
            await AppActions.delay(600);
            AppActions.setLoading(false);
            setShowRoomCart(true);
            setPrice(roomCart.total);
          }}
          removeChosenRoom={removeChosenRoom}
          checkout={checkout}
          goBack={goBack}
          byAdmin={byAdmin}
          setOpenNoticeDialog={setOpenNoticeDialog}
        />
      </div>
      <NoticeModal
        visible={openNoticeDialog}
        onClose={() => setOpenNoticeDialog(false)}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  padding-top: var(--topNavBarHeight);
  padding-bottom: 60px;
  background-color: #eee;

  &:before {
    content: '';
    background-image: url('/images/booking-background.jpeg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  & > .content {
    max-width: var(--contentMaxWidth);
    /* height: calc(100vh - var(--topNavBarHeight) - 133px); */
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 160px;
    display: flex;
    justify-content: center;
    align-items: stretch;

    & > .block {
      flex: 1;
      overflow: scroll;
      background-color: #fff5;
      backdrop-filter: blur(20px);
    }

    & .rev-calendar-container {
      max-width: 600px;
      margin: 0 auto 60px auto;
      & .r-date {
        position: relative;
      }

      & .r-cell.r-date.r-today {
        border: solid 1px #fff;
      }

      & .r-cell.r-date:hover,
      .r-next:hover,
      .r-prev:hover,
      .r-cell.r-date.r-selected {
        background-color: #ccedfc;
        border: solid 1px #ccedfc;
      }
    }
  }
`;
