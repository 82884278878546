import React from 'react';
import styled from 'styled-components';
import RoomDetailModal from './RoomDetailModal';
import constants from '../../constants';
import * as AppActions from '../../AppActions';

export default function RoomList(props) {
  const {rooms, chooseRoom} = props;
  const [selectedRoom, setSelectedRoom] = React.useState(null);

  if (rooms.length === 0) {
    return (
      <div style={{textAlign: 'center'}}>
        <img
          src="/images/site-logo.png"
          alt="logo"
          style={{width: 200, marginBottom: 30}}
        />
        <div style={{marginBottom: 30, fontSize: 16}}>
          {AppActions.L('no_remaining_rooms')}
        </div>
      </div>
    );
  }

  return (
    <Wrapper>
      {rooms.length > 0 && (
        <div className="content">
          {rooms.map((room) => {
            return (
              <div key={room.id} onClick={() => setSelectedRoom(room)}>
                <img
                  src={
                    (room.images || [])[0]?.expected_url ||
                    '/images/revicon_512.png'
                  }
                  alt="room"
                />
                <div className="content">
                  <div className="product-name">{room.name}</div>

                  <div className="intro">{room.intro}</div>
                  <div style={{flex: 1}} />
                  <div className="bottom">
                    <div className="price">
                      <span style={{color: '#707070'}}>NT$</span>{' '}
                      {room.current_price}
                    </div>
                    <div style={{flex: 1}} />
                    <div>{AppActions.L('remaining_number')}：</div>
                    <div>{room.remaining}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <RoomDetailModal
        visible={!!selectedRoom}
        setVisible={(visible) => {
          if (!visible) {
            setSelectedRoom(null);
          }
        }}
        room={selectedRoom}
        chooseRoom={(...args) => {
          chooseRoom(...args);
          setSelectedRoom(null);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .content {
    padding: 10px;

    & > * {
      display: flex;
      padding: 10px;
      border-radius: 8px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.11);
      background-color: white;
      cursor: pointer;
      transition: all 200ms;

      &:hover {
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.33);
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      & img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        margin-right: 10px;
        border-radius: 5px;
      }

      & > .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        & > .product-name {
          font-size: 1.2em;
          font-weight: 500;
        }
        & > .intro {
          color: #bbb;
          height: 42px;
          overflow: hidden;
        }
        & .price {
          color: ${constants.THEME_COLOR};
        }
        & > .bottom {
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;
