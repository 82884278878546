import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import {getNewOutlet, getOutlet} from 'reconnect.js';
import * as settings from '../../../data.json';

const RoomCartOutlet = getNewOutlet('roomCart', null, {autoDelete: false});

export async function fetchRoomCart() {
  const UserOutlet = getOutlet('user');
  RoomCartOutlet.update(
    await ApiUtil.req(
      `${settings.apiHost}/room-cart?token=${UserOutlet.getValue().token}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ),
  );
}

export async function searchRoom({from_date, to_date}) {
  const rooms = await ApiUtil.req(`${settings.apiHost}/room/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      from_date,
      to_date,
    }),
  });
  return rooms.map((r) => {
    if (r.id) {
      return r;
    }
    return {
      ...r,
      id: r._id.$oid,
    };
  });
}

export async function clearRoomCart() {
  const UserOutlet = getOutlet('user');
  RoomCartOutlet.update(
    await ApiUtil.req(
      `${settings.apiHost}/room-cart/clear?token=${
        UserOutlet.getValue().token
      }`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ),
  );
}

export async function addRoomToCart({room, qty, from_date, to_date}) {
  const UserOutlet = getOutlet('user');
  RoomCartOutlet.update(
    await ApiUtil.req(
      `${settings.apiHost}/room-cart/item/add?token=${
        UserOutlet.getValue().token
      }`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product: room.id,
          config: {
            qty,
            variants: [],
            extra_data: '',
            from_date,
            to_date,
          },
        }),
      },
    ),
  );
}

export async function removeRoomFromCart(idx) {
  const UserOutlet = getOutlet('user');
  RoomCartOutlet.update(
    await ApiUtil.req(
      `${settings.apiHost}/room-cart/item/delete?token=${
        UserOutlet.getValue().token
      }`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          index: idx,
        }),
      },
    ),
  );
}

export async function editRoomCartConfig(data) {
  const UserOutlet = getOutlet('user');
  RoomCartOutlet.update(
    await ApiUtil.req(
      `${settings.apiHost}/room-cart/edit-config?token=${
        UserOutlet.getValue().token
      }`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    ),
  );
}

export async function checkoutBookingRequest({adults, kids}) {
  const UserOutlet = getOutlet('user');
  const resp = await ApiUtil.req(
    `${settings.apiHost}/room-payment/checkout?token=${
      UserOutlet.getValue().token
    }&adults=${adults}&kids=${kids}`,
  );
  window.location.href = resp.redirect_url;
}

export async function adminReserveRoom({bookingInfo, adminAccessToken}) {
  const UserOutlet = getOutlet('user');
  return ApiUtil.req(
    `${settings.apiHost}/room/reserve?token=${adminAccessToken}&anon_token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bookingInfo),
    },
  );
}
