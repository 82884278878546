import React from 'react';
import styled from 'styled-components';
import {DT} from './Util';
import * as AppActions from '../../AppActions';
import constants from '../../constants';

export default function DateCell(props) {
  const {
    year,
    month,
    date,
    classNames: _classNames,
    onClick,
    startDate,
    endDate,
  } = props;
  const curr = DT({year, month, date});

  function isTheSameDate(d) {
    return d?.year === year && d?.month === month && d?.date === date;
  }

  // function getDateStringStyle() {
  //   const resultStyle = {
  //     color: 'black',
  //   };

  //   if (startDate) {
  //     const curr = DT({year, month, date});
  //     const start = DT(startDate);

  //     if (start.getTime() > curr.getTime()) {
  //       resultStyle.color = '#ccc';
  //     }

  //     if (endDate) {
  //       const end = DT(endDate);
  //       if (
  //         curr.getTime() >= start.getTime() &&
  //         curr.getTime() <= end.getTime()
  //       ) {
  //         resultStyle.color = constants.THEME_COLOR;
  //         resultStyle.fontWeight = 'bold';
  //       }
  //     }
  //   }

  //   return resultStyle;
  // }

  let classNames = [];
  let isStart = false,
    isEnd = false;

  // today >= 5號 -> 4month, today < 5號 -> 3month
  const today = new Date();
  let lastDateOfMonth = new Date(
    today.getFullYear(),
    today.getMonth() + (today.getDate() >= 5 ? 4 : 3),
    0,
  );
  if (curr.getTime() > lastDateOfMonth.getTime()) {
    classNames.push('disabled');
  }
  if (isTheSameDate(startDate)) {
    classNames.push('r-start');
    isStart = true;
  } else if (isTheSameDate(endDate)) {
    classNames.push('r-end');
    isEnd = true;
  } else if (startDate && endDate) {
    const start = DT(startDate);
    const end = DT(endDate);

    if (curr.getTime() >= start.getTime() && curr.getTime() <= end.getTime()) {
      classNames.push('r-during');
    }
  }

  return (
    <Wrapper
      // key={date}
      className={_classNames.concat(classNames).join(' ')}
      label={isStart ? AppActions.L('begin') : isEnd ? AppActions.L('end') : ''}
      onClick={(e) => {
        if (!_classNames.concat(classNames).includes('disabled')) {
          onClick(e);
        }
      }}>
      {date}
    </Wrapper>
  );

  // return (
  //   <Wrapper>
  //     {isTheSameDate(startDate) && (
  //       <div className="label start">
  //         <div style={{backgroundColor: constants.THEME_COLOR}}>
  //           {AppActions.L('begin')}
  //         </div>
  //       </div>
  //     )}
  //     {isTheSameDate(endDate) && (
  //       <div className="label end">
  //         <div style={{backgroundColor: 'teal'}}>{AppActions.L('end')}</div>
  //       </div>
  //     )}
  //     <div style={getDateStringStyle()}>{date}</div>
  //   </Wrapper>
  // );
}

const Wrapper = styled.div`
  position: relative;

  &.r-start,
  &.r-end,
  &.r-during {
    color: ${constants.THEME_COLOR};
    font-weight: bold;
  }

  &.r-today::after {
    content: ' ';
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${constants.THEME_COLOR_SECOND};
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }

  &.r-start::after,
  &.r-end::after {
    content: '${(props) => props.label}';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 5px;
    padding: 2px 8px;
    border-radius: 32px;
    width: auto;
    height: auto;
  }
  &.r-start::after {
    background-color: ${constants.THEME_COLOR};
  }
  &.r-end::after {
    background-color: teal;
  }
`;

// const Wrapper = styled.div`
//   width: 100%;
//   height: 100%;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   & > .label {
//     position: absolute;
//     top: -6px;
//     left: 0;
//     width: 100%;
//     display: flex;
//     justify-content: center;

//     & > * {
//       padding: 2px 8px;
//       border-radius: 32px;
//       background-color: hotpink;
//       color: white;
//       font-size: 5px;
//     }
//   }
// `;
